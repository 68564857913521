











import {
  defineComponent,
  SetupContext,
  provide,
  InjectionKey,
  ref,
  watch,
} from '@vue/composition-api'

export const ON_CURRENT_PAGE_CHANGE_KEY: InjectionKey<(currentPage: number) => void> = Symbol('injection key of onCurrentPageChange Func')

export default defineComponent({
  name: 'PaginationContainer',
  props: {
    perPage: {
      type: Number,
      default: 10,
    },
    total: {
      type: Number,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    }
  },
  setup(props, context: SetupContext) {
    const listSliceIndices = ref<[number, number]>([0, props.perPage])

    watch(() => [props.currentPage, props.perPage], ([currentPage, perPage]) => {
      const startIdx = perPage * Math.max(currentPage - 1, 0)
      listSliceIndices.value = [startIdx, startIdx + perPage]
    }, { immediate: true })

    const onCurrentPageChange = (currentPage: number):void => {
      context.emit('current-page-change', currentPage)
    }
    provide(ON_CURRENT_PAGE_CHANGE_KEY, onCurrentPageChange)

    return {
      listSliceIndices,
    }
  }
})
