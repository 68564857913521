/* dashboardサンプルの中でresizeイベントのthrottleとして使われていたので残しておく. */
export function uiResizeThrottle(handlerFunc: () => void, timeout = 66) {
  let resizeTimeout: number | null = null
  if (!resizeTimeout) {
    resizeTimeout = window.setTimeout(() => {
      resizeTimeout = null
      handlerFunc()
      // The actualResizeHandler will execute at a rate of 15fps
    }, timeout)
  }
}
