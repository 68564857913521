import { DEFAULT_DAY_START_HOUR, ROUTER_DT_PARAM_TODAY } from 'src/consts'
import moment from 'src/util/moment-ja'

export default {
  methods: {
    dtUtilGetNow() {
      let dt = new Date()
      // サーバー側から埋め込まれたnowがあればそれを使うようにする.
      const nowObj = this.$store.getters['user/nowObj']
      if (!nowObj.is_real_now) {
        dt = new Date(nowObj.now)
      }
      return moment(dt)
    },
    getAlignedNow(step) {
      // step単位の分にalignされたnowを返す.
      // 時間的に未来側に揃える.
      const now = this.dtUtilGetNow()
      now.seconds(0).milliseconds(0)

      step = parseInt(step)
      if (!isNaN(step) && step > 1) {
        let seconds = now.hours() * 60 + now.minutes()
        seconds = Math.ceil(seconds / step) * step
        now.hours(parseInt(seconds / 60))
        now.minutes(parseInt(seconds % 60))
      }
      return now
    },
    dateToMoment(dt) {
      return moment(dt).clone()
    },
    getWorkDate(dt) {
      // 営業日付を取得する
      if (!dt) { dt = this.dtUtilGetNow() }
      dt = moment(dt).clone()
      if (dt.hours() < DEFAULT_DAY_START_HOUR) {
        dt.subtract(1, 'day')
      }
      dt.hours(0).minutes(0).seconds(0).milliseconds(0)
      return dt
    },
    getWorkDateFromDtParam(dtParam) {
      if (!dtParam || dtParam === ROUTER_DT_PARAM_TODAY) {
        return this.getWorkDate()
      } else {
        if (dtParam.match(/\d{4}-\d{2}-\d{2}/)) {
          return moment(dtParam)
        } else {
          return this.getWorkDate()
        }
      }
    },
    dateToTimeInteger(dt) {
      let h = dt.getHours()
      const m = dt.getMinutes()
      const s = dt.getSeconds()
      if (h < DEFAULT_DAY_START_HOUR) {
        h += 24
      }
      return h * 10000 + m * 100 + s
    },
    ensureTimeInteger(t) {
      if (t instanceof Date) {
        t = this.dateToTimeInteger(t)
      }
      if (isNaN(t)) { return NaN }
      return t
    },
    packToTimeInteger(h, m, s) {
      return parseInt(h) * 10000 + parseInt(m) * 100 + parseInt(s)
    },
    unpackTimeIntegerToString(timeInt) {
      let [h, m, s] = this.unpackTimeInteger(timeInt)
      h = ('0' + h).slice(-2)
      m = ('0' + m).slice(-2)
      s = ('0' + s).slice(-2)
      return [h, m, s]
    },
    unpackTimeInteger(timeInt) {
      let tmp = parseInt(timeInt)
      const h = parseInt(timeInt / 10000)
      tmp -= h * 10000
      const m = parseInt(tmp / 100)
      const s = tmp % 100
      return [h, m, s]
    },
    timeDifferenceInSeconds(t1, t2) {
      t1 = this.ensureTimeInteger(t1)
      t2 = this.ensureTimeInteger(t2)
      const [h1, m1, s1] = this.unpackTimeInteger(t1)
      const [h2, m2, s2] = this.unpackTimeInteger(t2)
      const sumSec1 = h1 * 3600 + m1 * 60 + s1
      const sumSec2 = h2 * 3600 + m2 * 60 + s2
      return sumSec2 - sumSec1
    },

    secondsToTimeInteger(seconds) {
      // 一旦正に戻してから計算
      const sign = seconds < 0 ? -1 : 1
      seconds *= sign

      let h = parseInt(seconds / 3600)
      h = Math.min(h, 99) // digit guard
      const tmp = seconds % 3600
      let m = parseInt(tmp / 60)
      let s = tmp % 60

      h = ('0' + h).slice(-2)
      m = ('0' + m).slice(-2)
      s = ('0' + s).slice(-2)
      return parseInt(`${h}${m}${s}`) * sign
    },
    timeIntegerToSeconds(tm) {
      // 一旦正に戻してから計算
      const sign = tm < 0 ? -1 : 1
      tm *= sign

      const h = parseInt(tm / 10000)
      const tmp = tm % 10000
      const m = parseInt(tmp / 100)
      const s = tmp % 100

      return (h * 3600 + m * 60 + s) * sign
    },
    timeIntegerAdd(tm1, tm2) {
      const sec1 = this.timeIntegerToSeconds(tm1)
      const sec2 = this.timeIntegerToSeconds(tm2)
      return this.secondsToTimeInteger(sec1 + sec2)
    },
  }
}
