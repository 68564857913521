import dateTimeMixin from 'src/mixins/dateTimeMixin'

export default {
  mixins: [dateTimeMixin],
  methods: {
    doNotify(msg, opts = {}) {
      const notifyObj = {
        message: msg,
        verticalAlign: opts.verticalAlign || 'top', // top | bottom
        horizontalAlign: opts.horizontalAlign || 'center', // right | center | left
        type: opts.type || 'info', // info | warning | danger | success | primary
        timeout: opts.timeout || 5 * 1000,
      }
      if (opts.title) { notifyObj.title = opts.title }
      if (opts.icon) { notifyObj.icon = opts.icon }
      if (opts.component) { notifyObj.component = opts.component }

      this.$notify(notifyObj)
    },
    notifySuccess1(msg, opts = {}) {
      opts.timeout = opts.timeout || 3 * 1000
      this.doNotify(msg, opts)
    },
    notifyError1(msg, opts = {}) {
      console.error(msg)
      if (opts.err) {
        console.error(opts.err)
      }
      opts.type = opts.type || 'danger'
      opts.timeout = opts.timeout || 60 * 1000
      opts.icon = opts.icon || 'fas fa-exclamation-triangle'
      this.doNotify(msg, opts)
    },
    notifyError2(msg, opts = {}) {
      opts.verticalAlign = opts.verticalAlign || 'bottom'
      this.notifyError1(msg, opts)
    },
    // 個別の通知
    notifyAttendanceUpdateSuccess(msgStructure, opts = {}) {
      const word1 = msgStructure.detail.work_end_time === null ? '出勤' : '退勤'
      const renderFunc = createElement => {
        const headerElem = createElement('div', { class: 'msg-header' },
          [
            createElement('span', { class: 'staff-name' }, msgStructure.header.name),
            createElement('span', `さんの${word1}を登録しました`)
          ])

        const detail = msgStructure.detail
        const content = []
        content.push(createElement('span', { class: 'lbl' }, '勤務'))
        let h, m
        [h, m] = this.unpackTimeIntegerToString(detail.work_start_time)
        content.push(createElement('span', { class: 'start-time' }, `${h}:${m}`))
        content.push(createElement('span', { class: 'tilda' }, '〜'))
        if (detail.work_end_time !== null) {
          [h, m] = this.unpackTimeIntegerToString(detail.work_end_time)
          content.push(createElement('span', { class: 'end-time' }, `${h}:${m}`))
        }
        const detailElem = createElement('div', { class: 'msg-line' }, content)

        return createElement('div', [ headerElem, detailElem ])
      }
      opts.timeout = opts.timeout || 3 * 1000
      opts.component = { render: renderFunc }
      this.doNotify('', opts)
    },
    notifyAttendanceReopenSuccess(msgStructure, opts = {}) {
      const renderFunc = createElement => {
        const headerElem = createElement('div', { class: 'msg-header' },
          [
            createElement('span', { class: 'staff-name' }, msgStructure.header.name),
            createElement('span', `退勤登録を取り消しました`)
          ])
        return createElement('div', [ headerElem ])
      }
      opts.timeout = opts.timeout || 3 * 1000
      opts.component = { render: renderFunc }
      this.doNotify('', opts)
    },
    notifyActivityUpdateSuccess(msgStructure, opts = {}) {
      const renderFunc = createElement => {
        const header = createElement('div', { class: 'msg-header' },
          [
            createElement('span', { class: 'staff-name' }, msgStructure.header.name),
            createElement('span', 'さんの作業を登録しました')
          ])
        const details = msgStructure.details.map(line => {
          const content = []
          content.push(createElement('span', { class: 'lbl' }, line.activity_master.name))
          let h, m
          [h, m] = this.unpackTimeIntegerToString(line.start_time)
          content.push(createElement('span', { class: 'start-time' }, `${h}:${m}`))
          content.push(createElement('span', { class: 'tilda' }, '〜'))
          if (line.end_time !== null) {
            [h, m] = this.unpackTimeIntegerToString(line.end_time)
            content.push(createElement('span', { class: 'end-time' }, `${h}:${m}`))
          }
          return createElement('div', { class: 'msg-line' }, content)
        })
        return createElement('div', [ header, ...details ])
      }
      opts.timeout = opts.timeout || 3 * 1000
      opts.component = { render: renderFunc }
      this.doNotify('', opts)
    },
  }
}
