export const DEFAULT_DAY_START_HOUR = 7

export const ACTIVITY_STATUS_WORKING = '1'
export const ACTIVITY_STATUS_WAITING = '2'
export const ACTIVITY_STATUS_RESTING = '3'
export const ACTIVITY_STATUS_NON_TRACKED = '4'
// export const ACTIVITY_STATUS_UNKNOWN = '91' // 登録なしもステータスの一種として取り扱いたいので
export const ACTIVITY_STATUS_LEFT_WORK = '92' // 退勤済もステータスの一種として取り扱いたいので
export const ACTIVITY_STATUS_ON_HELP = '93' // 応援中もステータスの一種として取り扱いたいので

export const ACTIVITY_HEADER_STATUS_READY = 1
export const ACTIVITY_HEADER_STATUS_RUNNING = 2
export const ACTIVITY_HEADER_STATUS_PAUSED = 3
export const ACTIVITY_HEADER_STATUS_COMPLETED = 4

export const USER_WORKPLACE_ROLE_W_OWNER = 'w_owner'
export const USER_WORKPLACE_ROLE_W_LEADER = 'w_leader'
export const USER_WORKPLACE_ROLE_W_MEMBER = 'w_member'

export const USER_WORKPLACE_STATUS_SHARED = '01'
export const USER_WORKPLACE_STATUS_PENDING = '11'
export const USER_WORKPLACE_STATUS_DECLINED = '12'

export const ROUTER_DT_PARAM_TODAY = 'today'

export const CODE_READER_TYPE_BARCODE = 'barcode'
export const CODE_READER_TYPE_QRCODE = 'qrcode'

export const QUANTITY_CALC_TYPE_DIRECT_INPUT = '1'
export const QUANTITY_CALC_TYPE_SUM = '2'

export const INDIRECT_COST_ITEM_TYPE_SALES = '1'
export const INDIRECT_COST_ITEM_TYPE_EXPENSE = '2'

export const WORK_START_TIME_INPUT_TYPE_NOT_ROUNDED = '1'
export const WORK_START_TIME_INPUT_TYPE_ROUNDED_UP = '2'
export const WORK_START_TIME_INPUT_TYPE_ROUNDED_DOWN = '3'
export const WORK_END_TIME_INPUT_TYPE_NOT_ROUNDED = '1'
export const WORK_END_TIME_INPUT_TYPE_ROUNDED_UP = '2'
export const WORK_END_TIME_INPUT_TYPE_ROUNDED_DOWN = '3'
export const ACTIVITY_TIME_INPUT_TYPE_NOT_ROUNDED = '1'
export const ACTIVITY_TIME_INPUT_TYPE_ROUNDED_UP = '2'
export const ACTIVITY_TIME_INPUT_TYPE_ROUNDED_DOWN = '3'

// シンプルモード_QRあり
export const TABLET_PATTERN_INPUT_MODE_CONTINUAL = 1
// 詳細モード（旧）
export const TABLET_PATTERN_INPUT_MODE_DETAIL = 2
// シンプルモード_QRなし
export const TABLET_PATTERN_INPUT_MODE_CONTINUAL_NO_QR_CODE = 3
// 詳細モード
export const TABLET_PATTERN_INPUT_MODE_DETAIL_2 = 4

export interface CalendarEventColorInfo {
  dispName: string
  colorName: string
  colorCode: string
}
export const CALENDAR_EVENT_COLORS: CalendarEventColorInfo[] = [
  // sass/paper/plugins/_plugin-fullcalendar.scss と連動している必要がある
  { dispName: '紺', colorName: 'darkblue', colorCode: 'rgba(0, 0, 102, 1.0)' },
  { dispName: '青', colorName: 'blue', colorCode: 'rgba(68, 114, 196, 1.0)' },
  { dispName: '水', colorName: 'lightblue', colorCode: 'rgba(51, 204, 204, 1.0)' },
  { dispName: '緑', colorName: 'green', colorCode: 'rgba(112, 173, 71, 1.0)' },
  { dispName: '草', colorName: 'grassgreen', colorCode: 'rgba(153, 204, 0, 1.0)' },
  { dispName: '黄', colorName: 'yellow', colorCode: 'rgba(255, 192, 0, 1.0)' },
  { dispName: '橙', colorName: 'orange', colorCode: 'rgba(237, 125, 49, 1.0)' },
  { dispName: '赤', colorName: 'red', colorCode: 'rgba(250, 30, 67, 1.0)' },
  { dispName: '紫', colorName: 'purple', colorCode: 'rgba(153, 51, 102, 1.0)' },
  { dispName: '灰', colorName: 'gray', colorCode: 'rgba(127, 127, 127, 1.0)' },
  { dispName: '黒', colorName: 'black', colorCode: 'rgba(59, 56, 56, 1.0)' },
  { dispName: '薄 青', colorName: 'pale-blue', colorCode: 'rgba(180, 199, 231, 1.0)' },
  { dispName: '薄 緑', colorName: 'pale-green', colorCode: 'rgba(197, 224, 180, 1.0)' },
  { dispName: '薄 黄', colorName: 'pale-yellow', colorCode: 'rgba(255, 230, 153, 1.0)' },
  { dispName: '薄 赤', colorName: 'pale-red', colorCode: 'rgba(248, 203, 173, 1.0)' },
  { dispName: '薄 灰', colorName: 'pale-gray', colorCode: 'rgba(217, 217, 217, 1.0)' },
]

// el-date-pickerオプション
export const DATE_PICKER_DEFAULTS = {
  // 月曜始まり
  firstDayOfWeek: 1,
}

// // コスト計上タイプ
// export const COST_CALC_TYPE_INCLUDE_ALL = '0'
// export const COST_CALC_TYPE_EXCLUDE_WAITING = '1'

// 契約形態
export const CONTRACT_TYPE_PIECERATE = '0'
export const CONTRACT_TYPE_OPENBOOK = '1'
export const CONTRACT_TYPE_CENTERFEE = '2'

// 出退勤カテゴリー
export const AttendanceCategory = {
  SHUKKIN: 'shukkin',
  TAIKIN: 'taikin',
}
export const ActionNameMap: Record<string, string> = {
  create: '作成',
  update: '編集',
  bulkUpdate: '一括編集'
}
export type AttendanceCategoryType = typeof AttendanceCategory[keyof typeof AttendanceCategory]

// 出勤・退勤申請タイプ
export const SA_START_TYPE_NORMAL = '0'
export const SA_START_TYPE_EARLY = '1'
export const SA_START_TYPE_LATE = '2'
export const SA_END_TYPE_NORMAL = '0'
export const SA_END_TYPE_EARLY = '1'
export const SA_END_TYPE_LATE = '2'

// 検索日付指定
// 31日間選択可能で、MINとMAXの差が30
export const SEARCH_DATE_RANGE_MAX = 30

// データ画面表示件数
export const DATA_DISPLAY_ITEM_COUNT = 5000
// データ画面一括変更可能件数
export const DATA_MAX_BULK_UPDATE_ITEM_COUNT = 50
// CSV出力可能件数
export const CSV_DOWNLOAD_ITEM_COUNT = 20000
// FC連携CSV (連携前 確認用)、FC連携CSV (システム連携用)出力可能件数
export const LOGISCO_TARGET_ACTIVITIES_COUNT = 8000
