var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-group",class:[
     {'input-group': _vm.hasIcon},
     {'has-danger': _vm.error},
     {'input-group-focus': _vm.focused},
     {'has-label': _vm.label || _vm.$slots.label},
     {'has-success': _vm.hasSuccess}]},[_vm._t("label",function(){return [(_vm.label)?_c('label',{class:_vm.labelClasses},[_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.required)?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e()]):_vm._e()]}),(_vm.addonLeftIcon || _vm.$slots.addonLeft)?_c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text"},[_vm._t("addonLeft",function(){return [_c('i',{class:_vm.addonLeftIcon})]})],2)]):_vm._e(),_vm._t("default",function(){return [_c('input',_vm._g(_vm._b({ref:"input",staticClass:"form-control",class:[
        {'is-valid': _vm.hasSuccess},
        _vm.inputClasses,
        {'is-invalid': _vm.error},
        {'ta-right': _vm.textAlignRight},
        {'ta-center': _vm.textAlignCenter},
        {'has-addon-left': _vm.hasIconLeft},
        {'has-addon-right': _vm.hasIconRight} ],attrs:{"required":_vm.required,"aria-describedby":"addon-right addon-left"},domProps:{"value":_vm.value}},'input',_vm.$attrs,false),_vm.listeners))]}),(_vm.addonRightIcon || _vm.$slots.addonRight)?_c('div',{staticClass:"input-group-append"},[_c('span',{staticClass:"input-group-text"},[_vm._t("addonRight",function(){return [_c('i',{class:_vm.addonRightIcon})]})],2)]):_vm._e(),_vm._t("infoBlock"),_vm._t("helpBlock",function(){return [(_vm.showErrorMessage && _vm.error)?_c('div',{staticClass:"invalid-feedback error-text",class:{'mt-2': _vm.hasIcon}},[_vm._v(" "+_vm._s(_vm.error)+" ")]):_vm._e()]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }