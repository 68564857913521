import SoundPlayer from 'src/util/sound_player'

const INIT_SOUND_PLAYER = 'INIT_SOUND_PLAYER'
const PLAY_SOUND = 'PLAY_SOUND'

// the definition of initial state is required.
const state = {
  soundPlayer: null,
}

const getters = {
  pageName: state => state.pageName,
}

const actions = {
  initSoundPlayer({ commit }) {
    commit(INIT_SOUND_PLAYER)
  },
  play({ commit }, name) {
    commit(PLAY_SOUND, { name })
  },
}

const mutations = {
  [INIT_SOUND_PLAYER](state) {
    const soundPlayer = new SoundPlayer()
    soundPlayer.register({ uri: '/static/audio/ok1-loud1.mp3', name: 'okSound' }).then(() => {})
    soundPlayer.register({ uri: '/static/audio/ng1-loud1.mp3', name: 'ngSound' }).then(() => {})
    soundPlayer.register({ uri: '/static/audio/bt-click1.mp3', name: 'numberInputSound' }).then(() => {})
    soundPlayer.register({ uri: '/static/audio/cancel1.mp3', name: 'cancelSound' }).then(() => {})
    state.soundPlayer = soundPlayer
  },
  [PLAY_SOUND](state, { name }) {
    state.soundPlayer.play(name)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
