const SET_DEMO_PARAMS = 'SET_DEMO_PARAMS'
const SET_LAST_REFRESH_TS = 'SET_LAST_REFRESH_TS'
const SET_DEMO_PAUSE = 'SET_DEMO_PAUSE'
const CLEAR_DEMO_PARAMS = 'CLEAR_DEMO_PARAMS'

const _initialState = {
  startTime: null,
  endTime: null,
  refreshIntervalSec: 0,
  execTimeSpan: 0,
  progressSecPerInterval: 0,

  isPaused: false,
  lastRefreshTs: null,
}

const state = JSON.parse(JSON.stringify(_initialState))
state.lastRefreshTs = new Date(state.lastRefreshTs)

const getters = {
  isDemoMode: state => state.startTime !== null && state.endTime !== null && state.refreshIntervalSec && state.execTimeSpan,
  isDemoPaused: state => state.isPaused,
  demoParams: state => {
    return {
      startTime: state.startTime,
      endTime: state.endTime,
      refreshIntervalSec: state.refreshIntervalSec,
      execTimeSpan: state.execTimeSpan,
      progressSecPerInterval: state.progressSecPerInterval,
    }
  },
  lastRefreshTs: state => state.lastRefreshTs
}

const actions = {
  setDemoParams({ commit }, data) {
    commit(SET_DEMO_PARAMS, { data })
  },
  setDemoPause({ commit }, shouldPause) {
    commit(SET_DEMO_PAUSE, { shouldPause })
  },
  setLastRefreshTs({ commit }, ts) {
    commit(SET_LAST_REFRESH_TS, { ts })
  },
  clearDemoParams({ commit }) {
    commit(CLEAR_DEMO_PARAMS)
  },
}

const mutations = {
  [SET_DEMO_PARAMS](state, { data }) {
    Object.keys(data).forEach(k => {
      state[k] = data[k]
    })
  },
  [SET_DEMO_PAUSE](state, { shouldPause }) {
    state.isPaused = shouldPause
  },
  [SET_LAST_REFRESH_TS](state, { ts }) {
    state.lastRefreshTs = ts
  },
  [CLEAR_DEMO_PARAMS](state) {
    const initialState = JSON.parse(JSON.stringify(_initialState))
    Object.keys(initialState).forEach(k => {
      state[k] = initialState[k]
    })
    state.lastRefreshTs = new Date(state.lastRefreshTs)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
