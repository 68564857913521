const SET_PAGE_NAME = 'SET_PAGE_NAME'

// the definition of initial state is required.
const state = {
  pageName: '',
}

const getters = {
  pageName: state => state.pageName,
}

const actions = {
  setPageName({ commit, state, getters }, { pageName }) {
    commit(SET_PAGE_NAME, { pageName })
  },
}

const mutations = {
  [SET_PAGE_NAME](state, { pageName }) {
    state.pageName = pageName
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
