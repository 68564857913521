import axios, { AxiosResponse } from 'axios'
import { getApiUrl } from './apiUtils'
import { WorkplaceResponse, WorkplacesResponse } from 'src/models/api/workplaceResponse'
import { CreateWorkplace, UpdateWorkplace } from 'src/models/api/workplaceRequest'
import { Workplace, workplaceResponseToWorkplace, workplacesResponseToWorkplaces } from 'src/models/workplace'
import { StringOrNumber } from 'src/models/common'

const apis = {
  index(): Promise<AxiosResponse<WorkplacesResponse>> {
    return axios.get(getApiUrl(`/workplaces`))
  },
  create(data: CreateWorkplace): Promise<AxiosResponse<WorkplaceResponse>> {
    return axios.post(getApiUrl(`/workplaces`), data)
  },
  update(workplaceId: StringOrNumber, data: UpdateWorkplace): Promise<AxiosResponse<WorkplaceResponse>> {
    return axios.put(getApiUrl(`/workplaces/${workplaceId}`), data)
  },
  show(workplaceId: StringOrNumber): Promise<AxiosResponse<WorkplaceResponse>> {
    return axios.get(getApiUrl(`/workplaces/${workplaceId}`))
  },
  delete(workplaceId: StringOrNumber): Promise<AxiosResponse<WorkplaceResponse>> {
    return axios.delete(getApiUrl(`/workplaces/${workplaceId}`))
  },
  acceptWorkplace(workplaceId: StringOrNumber): Promise<AxiosResponse<WorkplaceResponse>> {
    return axios.put(getApiUrl(`/workplaces/${workplaceId}/accept_workplace`))
  },
  declineWorkplace(workplaceId: StringOrNumber): Promise<AxiosResponse<WorkplaceResponse>> {
    return axios.put(getApiUrl(`/workplaces/${workplaceId}/decline_workplace`))
  },
}

export const apisWithTransformedData = {
  index(): Promise<Workplace[]> {
    return apis.index().then(({ data }) => workplacesResponseToWorkplaces(data))
  },
  create(data: CreateWorkplace): Promise<Workplace> {
    return apis.create(data).then(({ data }) => workplaceResponseToWorkplace(data))
  },
  update(workplaceId: StringOrNumber, data: UpdateWorkplace): Promise<Workplace> {
    return apis.update(workplaceId, data).then(({ data }) => workplaceResponseToWorkplace(data))
  },
  show(workplaceId: StringOrNumber): Promise<Workplace> {
    return apis.show(workplaceId).then(({ data }) => workplaceResponseToWorkplace(data))
  },
  delete(workplaceId: StringOrNumber): Promise<Workplace> {
    return apis.delete(workplaceId).then(({ data }) => workplaceResponseToWorkplace(data))
  },
}

export default apis
