export function getApiUrl(...args: string[]): string {
  if (args.length !== 1) {
    throw new Error('wrong number of arguments for utils.getApiUrl')
  }
  const path = args[0]
  // add .json extension so rails can always return a json response.
  // return `${path}.json`

  // 末尾にjsonつけるのをやめた
  return path
}
