import axios, { AxiosResponse } from 'axios'
import { getApiUrl } from './apiUtils'
import { UserResponse } from 'src/models/api/userResponse'

export default {
  login(data: any): Promise<AxiosResponse<UserResponse>> {
    return axios.post(getApiUrl('/users/login.json'), {
      user: data
    })
  },
  logout(): Promise<AxiosResponse<{}>> {
    return axios.delete(getApiUrl('/users/logout.json'))
  },
  getMe(): Promise<AxiosResponse<UserResponse>> {
    return axios.get(getApiUrl('/users/me'))
  },
  update(data: any): Promise<AxiosResponse<UserResponse>> {
    return axios.put(getApiUrl(`/users/${data.id}`), data)
  },
  sendResetPasswordEmail(data: any): Promise<AxiosResponse<{}>> {
    return axios.post(getApiUrl(`/users/send_reset_password_email`), data)
  },
  resetPassword(data: any): Promise<AxiosResponse<{}>> {
    return axios.post(getApiUrl(`/users/reset_password`), data)
  },
}
