import axios, { AxiosResponse } from 'axios'
import { getApiUrl } from './apiUtils'
import { StringOrNumber } from 'src/models/common'
import { TabletPatternResponse, TabletPatternsResponse } from 'src/models/api/tabletPatternResponse'
import {
  TabletPattern,
  tabletPatternResponseToTabletPattern,
  tabletPatternsResponseToTabletPatterns
} from 'src/models/tabletPattern'

const apis = {
  index(workplaceId: StringOrNumber, budgetGroupId: StringOrNumber): Promise<AxiosResponse<TabletPatternsResponse>> {
    return axios.get(getApiUrl(`/workplaces/${workplaceId}/budget_groups/${budgetGroupId}/tablet_patterns`))
  },
  show(workplaceId: StringOrNumber, budgetGroupId: StringOrNumber, tabletPatternId: StringOrNumber): Promise<AxiosResponse<TabletPatternResponse>> {
    return axios.get(getApiUrl(`/workplaces/${workplaceId}/budget_groups/${budgetGroupId}/tablet_patterns/${tabletPatternId}`))
  },
}

export const apisWithTransformedData = {
  index(workplaceId: StringOrNumber, budgetGroupId: StringOrNumber): Promise<TabletPattern[]> {
    return apis.index(workplaceId, budgetGroupId).then(({ data }) => tabletPatternsResponseToTabletPatterns(data))
  },
  show(workplaceId: StringOrNumber, budgetGroupId: StringOrNumber, tabletPatternId: StringOrNumber): Promise<TabletPattern> {
    return apis.show(workplaceId, budgetGroupId, tabletPatternId).then(({ data }) => tabletPatternResponseToTabletPattern(data))
  },
}

export default apis
