import axios from 'axios'

const AudioContext = window.AudioContext || window.webkitAudioContext

export default class SoundPlayer {
  private readonly context: AudioContext
  private readonly bufferDict: Record<string, AudioBuffer>

  constructor() {
    this.context = new AudioContext()
    this.bufferDict = {}
    this._webAudioTouchUnlock()
  }

  _webAudioTouchUnlock(): void {
    /*
     * https://github.com/pavle-goloskokovic/web-audio-touch-unlock/blob/master/src/index.ts
     * 最初にユーザー動作トリガで何か鳴らしておかないと、
     * 自由なタイミングで鳴らせない.
     * これをやってもtouchstartより先に音を鳴らそうとすると鳴らないが、
     * その場合は仕方ない.
     */
    if (!this.context) { return }

    if (this.context.state === 'suspended' && 'ontouchstart' in window) {
      const unlock = () => {
        this.context.resume().then(() => {
          document.body.removeEventListener('touchstart', unlock)
          document.body.removeEventListener('touchend', unlock)
        })
      }
      document.body.addEventListener('touchstart', unlock, false)
      document.body.addEventListener('touchend', unlock, false)
    }
  }

  async register({ uri, name }: { uri: string, name: string }): Promise<void> {
    const { data } = await axios({
      url: uri,
      baseURL: '', // App.vueあたりでbaseURLを設定してしまっているので打ち消し
      responseType: 'arraybuffer',
    })
    return new Promise((resolve, reject) => {
      this.context.decodeAudioData(data, (buf: AudioBuffer) => {
        this.bufferDict[name] = buf
        resolve()
      }, error => {
        reject(error)
      })
    })
  }

  play(name: string): void {
    if (!this.bufferDict[name]) { return }
    const source = this.context.createBufferSource()
    source.buffer = this.bufferDict[name]
    source.connect(this.context.destination)
    source.start(0)
  }

  destroy(): Promise<void> {
    for (const k in this.bufferDict) {
      delete this.bufferDict[k]
    }
    return this.context.close()
  }
}
