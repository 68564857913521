const SET_TABLET_PATTERN = 'SET_TABLET_PATTERN'

// the definition of initial state is required.
const state = {
  id: -1,
  budget_group_id: -1,
  budget_group: {},
  name: '',
  input_mode: '',
  tablet_pattern_panels: [],
}

const getters = {
  isTabletPatternSet: state => state.id !== -1,
  workplaceId: state => state.budget_group.workplace_id,
  budgetGroupId: state => state.budget_group_id,
  name: state => state.name,
  inputMode: state => state.input_mode,
  tabletPatternPanels: state => state.tablet_pattern_panels,
}

const actions = {
  setTabletPattern({ commit, state, getters }, tabletPattern) {
    commit(SET_TABLET_PATTERN, { tabletPattern })
  },
}

const mutations = {
  [SET_TABLET_PATTERN](state, { tabletPattern }) {
    Object.keys(tabletPattern).forEach(k => {
      state[k] = tabletPattern[k]
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
