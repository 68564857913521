import moment from 'src/util/moment-ja'

export const timeInteger = (timeInput: number | string | null, format = 'HH:mm'): string => {
  if (timeInput === null) { return '' }
  let tmpTimeInt = typeof timeInput === 'string' ? parseInt(timeInput) : timeInput
  if (isNaN(tmpTimeInt)) { return '' }
  const h = Math.floor(tmpTimeInt / 10000)
  tmpTimeInt -= h * 10000
  const min = Math.floor(tmpTimeInt / 100)
  const s = tmpTimeInt % 100

  // moment.js風に合わせる
  return format
    .replace(/HH/g, ('0' + h).slice(-2))
    .replace(/H/g, '' + h)
    .replace(/mm/g, ('0' + min).slice(-2))
    .replace(/m/g, '' + min)
    .replace(/ss/g, ('0' + s).slice(-2))
    .replace(/s/g, '' + s)
}

export const momentJa = (something: moment.MomentInput, format?: string) => {
  return moment(something).format(format)
}
