import { addComma, currency, addCommaFraction, addCommaFractionRegex, percent } from './number_filters'
import { timeInteger, momentJa } from './datetime_filters'
import { splitByLineBreak, firstLetter } from './disp_filters'

export default {
  addComma,
  addCommaFraction,
  addCommaFractionRegex,
  percent,
  currency,
  timeInteger,
  momentJa,
  splitByLineBreak,
  firstLetter
}
