import { VueConstructor } from 'vue'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'

function getRegularizedReleaseStage(releaseStage_: string): string {
  let releaseStage = releaseStage_
  if (releaseStage === 'stg') {
    releaseStage = 'staging'
  }
  return releaseStage
}

export default function initBugsnag(vue: VueConstructor, releaseStage: string): void {
  Bugsnag.start({
    apiKey: '58ee3e9ffc92235566e67e71440ca3b1',
    enabledReleaseStages: [ 'production', 'staging' ],
    autoTrackSessions: false,
    releaseStage: getRegularizedReleaseStage(releaseStage),
    plugins: [new BugsnagPluginVue()],
  })
  // @ts-ignore
  Bugsnag.getPlugin('vue').installVueErrorHandler(vue)
}
