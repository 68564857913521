import { Staff } from 'src/models/staff'

export function compareStaff<T extends Staff>(a: T, b: T, ignoreBudgetGroupOrder: boolean): number {
  if (!ignoreBudgetGroupOrder) {
    const n1 = a.budget_group ? a.budget_group.disp_order : 99999
    const n2 = b.budget_group ? b.budget_group.disp_order : 99999
    if (n1 < n2) { return -1 }
    if (n1 > n2) { return 1 }
  }

  // 半角スペースは全角よりも文字コード的に先頭側である.
  // 親鸞の鸞は文字コード的に相当末尾側である.
  let s1 = (a.family_name_kana || '鸞') + ' ' + (a.first_name_kana || '鸞')
  let s2 = (b.family_name_kana || '鸞') + ' ' + (b.first_name_kana || '鸞')
  if (s1 < s2) { return -1 }
  if (s1 > s2) { return 1 }

  s1 = a.family_name + ' ' + a.first_name
  s2 = b.family_name + ' ' + b.first_name
  return s1 < s2 ? -1 : s1 > s2 ? 1 : 0
}

export function sortStaffs<T extends Staff>(staffs: T[], ignoreBudgetGroupOrder: boolean = false): T[] {
  return staffs.sort((a, b) => {
    return compareStaff(a, b, ignoreBudgetGroupOrder)
  })
}
