export const splitByLineBreak = (str: string | null): string[] => {
  if (!str) { return [] }
  const token = '#@%;#'
  return str
    .replace(/\r\n|\\r\\n/g, token)
    .replace(/\r|\\r/g, token)
    .replace(/\n|\\n/g, token)
    .split(token)
}

export const firstLetter = (str: string): string => {
  if (str.match(/^[A-Za-z0-9]{2}/)) {
    return str.slice(0, 2)
  }
  return str.slice(0, 1)
}
